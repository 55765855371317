.SnackBar {
  min-width: 300px !important;
  position: absolute !important;
  border: 0 !important;
}

.bottomRight {
  margin-top: calc(100vh - 100px) !important;
  margin-left: calc(100vw - 350px) !important;
}

.topRight {
  margin-top: 100px !important;
  margin-left: calc(100vw - 350px) !important;
}

.topLeft {
  margin-top: 100px !important;
  margin-left: 350px !important;
}

.bottomLeft {
  margin-top: calc(100vh - 100px) !important;
  margin-left: 350px !important;
}
