@import '../../../../palette';

.filterbar {
  @include themed() {
    background-color: t($todo-table-header-background);
    border-top: 1px solid t($border);
  }
  display: flex;
  padding: 16px 8px 24px;

  .filterbutton {
    &.is-active {
      .singleselect {
        color: $hansoft-green;
      }
    }
  }

  .filterbutton + .filterbutton {
    margin: 0 0 0 16px;
  }

  .singleselect-container {
    min-width: 164px;
  }

  input {
    margin: 0 16px 0 0;
  }
}
