@import '../../../../palette';

.card {
  padding: 10px;
  margin: 10px;
  height: 120px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: grab;

  @include themed() {
    box-shadow: -2px 2px t($box-shadow);
  }

  .cover-image {
    cursor: pointer;
    max-height: 50px;
    max-width: 50px;
    width: auto;
    height: auto;
    object-fit: contain;
  }

  .color {
    height: 120px;
    width: 7px;
    margin: -10px 10px -10px -12px;
    border-radius: 5px;
  }

  .Card--body {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    .information-icons {
      display: flex;
      text-align: left;
      margin-left: 3px;
      @include themed() {
        color: t($titlebar-background) !important;
      }
    }
  }
}

.past-due {
  border: 2px solid $light-red;

  .color {
    height: 116px !important;
    margin: -10px 10px -10px -10px !important;
  }
}

.Card--normal {
  .scheduled-item-due-date {
    display: flex;
    margin-left: 3px;
  }

  .description-with-image {
    display: flex;
    flex-flow: row;

    .description {
      display: flex;
      flex-flow: column nowrap;
      max-width: 100%;
      overflow: hidden;

      .project {
        margin-left: 3px;
        text-align: left;
        color: $gray;
        font-size: 11px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @include themed() {
    background-color: t($board-card-background);
    a {
      color: t($text) !important;
      text-align: left;
      margin-left: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .icon {
    margin-right: 15px;
    margin-top: 5px;
    height: 16px;
    width: 16px;
  }
}

.Card--dragging {
  a {
    color: transparent !important;
  }

  border-color: transparent !important;

  color: transparent !important;
  @include themed() {
    background-color: t($boards-droppable-card);
  }

  .icon {
    display: none;
  }

  .color {
    display: none;
  }

  .singleselect-icon {
    display: none;
  }
}

.Card--disabled {
  cursor: not-allowed;
  opacity: 0;
}
