@import '../../../../palette';

.comment-button {
  color: $white;
  cursor: pointer;
}

.comment-button:hover {
  text-decoration: underline;
}

.comment-button.is-positive {
  color: $hansoft-green;
}

.Comment-Button-Positive {
  background-color: $hansoft-green !important;
  color: $white !important;
  text-align: right;
  min-width: 90px;
}

.Comment-Button-Positive:hover {
  background-color: $dark-green !important;
}

.Comment-Button-Positive:active {
  background-color: $light-green !important;
}

.Comment-Button-Negative {
  @include themed() {
    color: t($text) !important;
    background-color: t($background) !important;
    border: 1px solid t($border) !important;
  }
  text-align: right;
  min-width: 90px;
}

.Comment-Button-Negative:hover {
  @include themed() {
    color: t($text);
    background-color: t($hover);
    border: 1px solid t($border);
  }
}

.Comment-Button-Negative:active {
  @include themed() {
    color: t($text);
    background-color: t($background);
    border: 1px solid t($border);
  }
}

.comment-emptystate {
  color: $gray;
  font-size: 0.95em;
}

.commentitem-container {
  display: flex;
  flex-flow: column nowrap;
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.commentitem-container + .commentitem-container {
  margin: 24px 0 0 0;
}

.commentitem-details {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 8px 0;
}

.commentitem-author {
  font-weight: 700;
  margin: 0 8px 0 0;
}

.commentitem-date-text {
  color: $gray;
}

.commentitem-content {
  @include themed() {
    background-color: t($itemdetails-screen-background);
    border: 1px solid t($border);
  }

  border-radius: 4px;
  padding: 8px 12px;
  margin: 0 0 8px 0;
}

.commentitem-edit {
  color: $hansoft-green;
  cursor: pointer;
  font-size: 0.9em;
  flex: 0 0 auto;
}

.commentitem-edit:hover {
  text-decoration: underline;
}

.rdw-suggestion-dropdown {
  color: $pitch-black;
}

.rdw-mention-link {
  background-color: initial !important;
  color: $blue !important;
}

.comments-list {
  width: 100%;
  overflow-y: auto;
}

.comments-tab {
  margin: 10px;
}
