@import '../../../palette';

.Copyright {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  font-size: 11px;
  color: $medium-gray;

  .List {
    list-style: none outside none;
  }

  .Link {
    color: $medium-gray;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
}
