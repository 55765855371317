@import '../../palette';

.ErrorBoundary {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-image: url('../../images/login-helix.png');
  background-repeat: no-repeat;
  background-color: $very-black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;

  .HansoftLogo {
    text-align: center;
    height: 150px;
    width: 150px;
  }


  .ErrorMessage {
    text-align: center;
    margin-top: 5%;
    color: $white;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .buttonsRow {
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .primaryButton {
    background-color: $hansoft-green;
    margin-left: 20px;
    border-radius: 4px;
    border: 0;
    padding: 8px 16px;
    min-width: 120px;
    margin-bottom: 10px;
    color: $white;
    cursor: pointer;
    &:hover {
      background-color: lighten($hansoft-green, 5%);
    }
  }
}
