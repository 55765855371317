.container {
  display: flex;
  align-items: center;
  float: right;
}

.pagingIconDisabled {
  width: 15px;
  height: 15px;
}

.pagingIconEnabled {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.text {
  font-size: 13px;
}
