@import '../../../palette';

.EditHyperlinkStyle {
  width: 14px;
  height: 14px;
  cursor: pointer;
  border-radius: 0;
}

.ReadOnlyFieldStyle {
  font-family: Roboto, serif;
  color: $white;
  font-size: 1rem;
  max-width: 100%;
}
