@import '../../../palette';

.toDoBoards {
  @include themed() {
    display: flex;
    flex-grow: 1;
    flex-flow: column nowrap;
    flex-direction: column;
    align-items: flex-start;
    background-color: t($background) !important;
  }
}

.container {
  @include themed() {
    height: calc(100vh - 56px);
    background-color: t($background) !important;
    overflow: auto;
  }

  .empty-boards-message {
    text-align: center;
    margin-top: 100px;
    @include themed() {
      color: t($text);
    }
  }
}

.kanban-board {
  width: 100%;
}
