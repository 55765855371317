@import '../../../../palette';

.item {
  min-height: 30px;
  min-width: 200px;
  max-width: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .descriptionNoAccess {
    color: $gray;
  }

  .icon {
    height: 16px;
    margin: 8px;
    width: 16px;
  }

  .resourcesIcon {
    height: 16px;
    width: 16px;
    margin: 8px 8px 8px auto;
  }

  .points {
    color: $light-black;
  }
}
