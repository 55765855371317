.taskColor {
  height: 55px;
  width: 10px;
  margin: -10px 10px -10px -20px;
}

.taskColorWithImage {
  height: 120px;
  width: 10px;
  margin: -10px 10px -10px -20px;
}

.sprintIcon {
    margin-right: 5px;
    height: 16px;
    width: 16px;
}