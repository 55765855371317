.addTimeText {
    margin-right: 5px;
}

.field {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  padding: 5px 14px;
  height: 100%;
}

.timeSpentReadOnly {
  padding: 8px 16px;
  height: 100%;
}