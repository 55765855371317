$background: 'background';
$text: 'text';
$secondary-text: 'secondary-text';
$hover: 'hover';
$border: 'border';
$box-shadow: 'box-shadow';

$titlebar-background: 'titlebar-background';
$todo-table-header-background: 'todo-table-header-background';
$itemdetails-toolbar-background: 'itemdetails-toolbar-background';
$itemdetails-toolbar-hover: 'itemdetails-toolbar-hover';
$itemdetails-disabled: 'itemdetails-disabled';
$itemdetails-disabled-text: 'itemdetails-disabled-text';
$itemdetails-screen-background: 'itemdetails-screen-background';

$dashboards-background: 'dashboards-background';
$dashboards-tile-background: 'dashboards-tile-background';
$dashboards-itemview-row-background: 'dashboards-itemview-row-background';

$dashboards-gauge: 'dashboards-gauge';
$dashboards-gauge-off: 'dashboards-gauge-off';

$board-card-background: 'boards-card-background';
$boards-droppable-card: 'boards-droppable-card';
$board-background: 'board-background';
$board-column-background: 'board-column-background';

$white: #ffffff;
$almost-white: #f7f7f7;
$medium-white: #f2f4f7;
$wild-sand: #f5f5f5;
$very-light-gray: #dedede;
$light-gray: #e6e6e6;
$silver: #cdcaca;
$gray: #ababab;
$medium-gray: #8a8a8a;
$heavy-gray: #787878;
$dark-gray: #666666;
$very-dark-gray: #45464d;
$very-light-black: #3a3b40;
$light-black: #404040;
$dark: #303236;
$medium-black: #2d2e32;
$heavy-black: #2c2e37;
$very-black: #2b2b2d;
$almost-black: #28292e;
$black: #262626;
$cod-gray: #191818;
$pitch-black: #000000;
$red: #ff0000;
$light-red: #f74f5d;
$yellow: #ffd300;
$very-light-green: #add5bd;
$light-green: #55b58c;
$green: #00d300;
$hansoft-green: #12936f;
$dark-green: #157756;
$very-dark-green: #396055;
$blue: #26b9fc;
$light-cyan: #add8e6;
$very-light-cyan: #d8f0fd;
$white-cyan: #eaf7ff;

$themes: (
  light: (
    box-shadow: $silver,
    background: $white,
    board-background: $wild-sand,
    text: $black,
    secondary-text: $dark-gray,
    hover: $medium-white,
    border: $very-light-gray,
    titlebar-background: $light-black,
    todo-table-header-background: $almost-white,
    itemdetails-screen-background: $white,
    itemdetails-toolbar-background: $light-black,
    itemdetails-toolbar-hover: $light-black,
    itemdetails-disabled: $almost-white,
    itemdetails-disabled-text: $gray,
    dashboards-background: $almost-white,
    dashboards-tile-background: $white,
    dashboards-itemview-row-background: $almost-white,
    dashboards-gauge: $light-gray,
    dashboards-gauge-off: $white,
    boards-card-background: $almost-white,
    boards-droppable-card: $very-light-green,
    board-column-background: $white,
  ),
  dark: (
    box-shadow: $black,
    background: $medium-black,
    board-background: $medium-black,
    text: $white,
    secondary-text: $gray,
    hover: $very-light-black,
    border: $very-dark-gray,
    titlebar-background: $black,
    todo-table-header-background: $almost-black,
    itemdetails-screen-background: $almost-black,
    itemdetails-toolbar-background: $almost-black,
    itemdetails-toolbar-hover: $very-light-black,
    itemdetails-disabled: $heavy-black,
    itemdetails-disabled-text: $dark-gray,
    dashboards-background: $medium-black,
    dashboards-tile-background: $dark,
    dashboards-itemview-row-background: $heavy-black,
    dashboards-gauge: $very-dark-gray,
    dashboards-gauge-off: $dark,
    boards-card-background: $almost-black,
    boards-droppable-card: $very-dark-green,
    board-column-background: $very-dark-gray,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
