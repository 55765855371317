@import '../../../palette';

.readOnlyDate {
  font-family: 'Roboto', sans-serif;
  color: $white;
  font-size: 1rem;
  max-width: 100%;
}

.readOnlyBackground {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.fieldSegment {
  border-style: none;
  padding: 0;
}
