@import '../../palette';

.titlebar {
  @include themed() {
    background-color: t($titlebar-background);
  }

  align-items: center;
  color: $white;
  display: flex;
  flex: 0 0 56px;
  height: 56px;
  justify-content: space-between;
  padding: 0 8px;
  user-select: none;
  width: 100%;

  .title {
    align-items: center;
    display: flex;
    font-weight: 700;

    .logo {
      height: 32px;
      margin: 0 8px 0 0;
      width: 32px;
    }
  }

  .board-list-icon {
    margin-bottom: 3px;
  }

  .controls {
    align-items: center;
    display: flex;

    .search-input {
      border: 1px solid $light-black;
      border-radius: 24px;
      height: 24px;
      margin: 0 24px 0 0;
      padding: 0 8px;
      &:focus {
        border: 1px solid $hansoft-green;
        outline: none;
      }
    }
    .profile-menu {
      border: 2px solid transparent;
      margin: 0 8px 0 0;
      padding: 0 0 4px 0;

      &:hover {
        cursor: pointer;
      }

      .menu {
        left: initial;
        right: 0;
      }

      .icon {
        margin: 0 0 0 5px !important;
      }
      .checkmark {
        margin-right: 5px !important;
        padding-left: 0 !important;
      }

      div.item {
        padding-left: 25px !important;
      }

      div.item-with-checkbox {
        padding-left: 0 !important;
      }
    }
  }

  .titlebar-button {
    align-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: flex;
    margin: 0 32px 0 0;
    padding: 0 0 2px 0;
    white-space: nowrap;
    img {
      height: 14px;
      width: 14px;
      margin: 0 8px 0 0;
    }

    &.is-active {
      border-bottom: 2px solid $hansoft-green;
    }

    &.is-primary {
      background-color: $hansoft-green;
      border-radius: 4px;
      padding: 8px 16px;
      &:hover {
        background-color: lighten($hansoft-green, 5%);
      }
    }
  }

  .navigation-list {
    display: flex;
    flex-flow: row nowrap;
  }
}

.ui.dropdown {
  .menu {
    .item {
      color: $pitch-black;
      &:hover {
        background-color: $white !important;
        color: $pitch-black !important;
        font-weight: initial;
      }
    }
  }
}

@media only screen and (max-width: 512px) {
  .titlebar {
    .logo {
      display: none;
      visibility: hidden;
    }
    .titlebar-button {
      margin: 0 16px 0 0;
    }
    .controls .titlebar-button {
      display: none;
      visibility: hidden;
    }
    .profile .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 52px;
    }
  }
}
