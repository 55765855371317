.Title {
  align-items: center;
  font-family: Roboto, serif;
  font-size: 1.5rem;
}

.EditTitle {
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
  border-radius: 0 !important;
}
