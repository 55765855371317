@import '../../palette';

.task-create {
  .heading {
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-flow: row nowrap;
    font-size: 16px;
    font-weight: 700;
    justify-content: space-between;
    padding: 16px;
    @include themed() {
      background-color: t($background);
    }
    svg {
      path {
        @include themed() {
          stroke: t($text) !important;
        }
      }
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
  .field {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    .label {
      padding: 16px 0;
      min-width: 200px;
      width: 200px;
    }
    .singleselect-container {
      width: 100%;
    }
    .multiline-editor {
      width: 100%;
    }
  }
  .divider {
    @include themed() {
      background-color: t($border);
    }
    height: 2px;
    margin: 32px 0;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    button {
      border: 1px solid transparent;
      border-radius: 4px;
      background-color: $hansoft-green;
      color: white;
      cursor: pointer;
      padding: 8px 16px;
      outline: none;
      &:hover,
      &:focus {
        background-color: lighten($hansoft-green, 10%);
      }
      &:disabled {
        @include themed() {
          color: t($itemdetails-disabled-text);
        }
      }
    }
  }
}
